import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { CourseTilesHeader, CourseTilesWrapper, CourseTilesTable } from './CourseTiles.styles'
import Title from '../../components/title/Title'
import CourseTile from '../course-tile/CourseTile'


class CourseTiles extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <CourseTilesWrapper id={'courses'} className={`${this.props.className || ''} margin-bottom`}>
        <CourseTilesHeader className="column is-12">
          <Title title={this.props.title} />
        </CourseTilesHeader>
        <CourseTilesTable>
          {posts &&
            posts.map(({ node: post }, index) => {
              return <CourseTile post={post} key={index} />;
            })}
        </CourseTilesTable>
      </CourseTilesWrapper >
    )
  }
}

CourseTiles.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query CourseTilesQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "course-page" } } }
          sort: { fields: [frontmatter___courseOnline], order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                frontend_title
                templateKey
                price
                description
                courseOnline
                cook {
                  name
                }
                image {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <CourseTiles data={data} title={props.title} />}
  />
)
