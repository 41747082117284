import styled from 'styled-components';

export const FAQsWrapper = styled.div`

  min-width: unset;
  margin: auto;
  max-width: 832px;

  .text {
    margin-bottom: 50px;
  }
`;


