import styled from "styled-components";
import { breakpoints, margins, paddings } from "../../styles/styles";

export const CooksWrapper = styled.div`
`;

export const Logo = styled.div`
    
    padding: ${paddings.S};
    img {

        max-height: 40px;
        margin-left: ${margins.M};

        @media(${breakpoints.M}) {
            max-height: 60px;
        }

        @media(${breakpoints.L}) {
            max-height: 79px;
        }
    }
`;

export const LogoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;