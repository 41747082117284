import React from 'react'
import PropTypes from 'prop-types'
import { CooksWrapper } from './Cooks.styles'
import Title from '../../components/title/Title'
import 'react-awesome-slider/dist/styles.css';
import Slider from '../slider/Slider'
import RoundedList from '../rounded-list/RoundedList';
import { graphql, StaticQuery } from 'gatsby'

class Cooks extends React.Component {

  render() {

    let data = {}
    if (this.props.data && this.props.data.markdownRemark && this.props.data.markdownRemark.frontmatter) {
      data = this.props.data.markdownRemark.frontmatter;
    }

    return (<CooksWrapper className={`${this.props.className} margin-bottom columns`}>
      {this.props.type === 'slider' && <>
        <Title title={data.cooksTitle} centered={true} />
        <Slider items={data.cooks} className={'slider'} itemType={'Testimonial'} autoSlide={true} />
      </>}
      {this.props.type === 'icons' && <>
        <RoundedList items={data.cooks} fullWidth={true} />
      </>}
    </CooksWrapper>
    )
  }
}

Cooks.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
}

export default (props) => (
  <StaticQuery
    query={graphql`
    query CooksQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          cooksTitle
          cooks {
            title
            text
            image {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  src
                }
              }
            }
          }
        }
      }
    }
    `}
    render={(data) => <Cooks data={data} {...props} />}
  />
)
