import styled from "styled-components";
import { paddings } from "../../styles/styles";

export const FAQWrapper = styled.div`

  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: ${paddings.M};
  margin-bottom: 24px;

  & .icon {
    transform: rotate(0deg);
    transition: transform 0.15s ease-in-out;
    color: red;
  }

  &.open {

    & .icon {
      transform: rotate(45deg);
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
`;

export const FAQTitle = styled.span`
  font-weight: bold !important;
`;

export const FAQText = styled.div`
  overflow: hidden;
  display: flex;
  height: 0px;

  &.open {
    height: 100%;
  }
`;
