import styled from "styled-components";
import { breakpoints, colors, margins, paddings } from "../../styles/styles";

export const CourseTilesWrapper = styled.div`
`;

export const CourseTileContentWrapper = styled.div`
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    background-color: ${colors.secondary};
    padding: ${paddings.L};
    height: 453px;
    display: flex;
    flex-flow: column;
    background-position-y: center;
    background-size: cover;
`;

export const CourseTilesHeader = styled.div`
     
    h1 {
        @media(${breakpoints.L}) {
            margin: unset;
            text-align: left !important;
        }
    }
`;

export const CourseTilesTable = styled.div`

    display: grid;
    grid-gap: ${margins.M};
    
    @media(${breakpoints.M}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(${breakpoints.L}) {
        grid-template-columns: repeat(3, 1fr);
    }
`;