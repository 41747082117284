import * as React from 'react';
import { FAQTitle, FAQWrapper, FAQText, TitleWrapper } from './FAQ.styles';
import OpenSVG from '../../img/close.svg';

const FAQ = class extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  onOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <FAQWrapper className={`faq ${this.state.isOpen ? 'open' : ''}`}>
        <TitleWrapper onClick={this.onOpen}>
          <FAQTitle>{this.props.questions}</FAQTitle>
          <img src={OpenSVG} className={'icon'} alt={'FAQ-Frage öffnen'} />
        </TitleWrapper>
        <FAQText className={`faq ${this.state.isOpen ? 'open' : ''}`}><span dangerouslySetInnerHTML={{ __html: this.props.answer }} /></FAQText>
      </FAQWrapper>
    );
  };
};


export default FAQ;
