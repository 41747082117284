import React from 'react'
import { FAQsWrapper } from './FAQs.styles';
import PropTypes from 'prop-types'
import FAQ from '../../components/faq/FAQ';
import Title from '../../components/title/Title';

export const FAQs = ({ className, title, faqs }) => (
  <FAQsWrapper className={`${className} margin-bottom`}>
    <Title title={title} centered={true} />
    {
      faqs && faqs.map((faq, index) => {
        return <FAQ key={index} {...faq} />;
      })
    }
  </FAQsWrapper >
);


FAQs.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  faqs: PropTypes.arrayOf(PropTypes.object)
}

export default FAQs;
