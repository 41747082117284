import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../modules/layout/Layout'
import CourseTiles from '../modules/course-tiles/CourseTiles'
import Stage from '../modules/stage/Stage'
import LogoWall from '../modules/publicity/LogoWall'
import Voucher from '../modules/voucher/Voucher'
import FAQs from '../modules/faqs/FAQs'
import Testimonials from '../modules/testimonials/Testimonials'
import Separator from '../modules/separator/Separator'
import Payments from '../modules/payments/Payments'
import BackgroundImg from '../img/background.svg';
import Newsletter from '../modules/newsletter/Newsletter'
import { getLocalePrice } from '../utils/helpers'
import Cooks from '../modules/cooks/Cooks'
import Benefits from '../modules/Benefits/Benefits'
import USP from '../modules/USP/USP'

export const IndexPageTemplate = ({
  title,
  faqs,
  backgroundImage,
  stageVideoSmall,
  stageVideoLarge,
  publicityLogos,
  coursesTitle,
  stageText,
  separatorImageVouchers,
  benefits,
  benefitsTitle,
  USPTitle,
  USPBoxHeadline,
  USPBoxBullets,
  USPImage,
  testimonials
}) => (

  <div className={'wrapper'} style={{ backgroundImage: `url(${BackgroundImg})`, backgroundRepeat: 'repeat-y', backgroundSize: 'contain' }}>
    <Stage title={title} subtitle={stageText} hasPurchaseButton={true} purchaseText={`ab ${getLocalePrice(39, true)} pro Kurs`} exploreCourses={'Kurse entdecken'} image={backgroundImage} stageVideoSmall={stageVideoSmall} stageVideoLarge={stageVideoLarge} courseOnline={true} isCoursePage={false} />
    <section className={'content'}>
      <Benefits benefits={benefits} benefitsTitle={benefitsTitle} />
      <CourseTiles title={coursesTitle} />
      <Cooks type={'slider'} className={'padding-bottom'} />
      <Voucher />
      <Separator image={separatorImageVouchers} />
      <Testimonials testimonials={testimonials} />
      <Separator />
      <USP USPTitle={USPTitle} USPBoxHeadline={USPBoxHeadline} USPBoxBullets={USPBoxBullets} USPImage={USPImage} />
      <Separator />
      <Payments />
      <Separator />
      <FAQs faqs={faqs} title={'FAQs'} />
      <Separator />
      <LogoWall className={'publicity'} title={'Auch<br/>bekannt aus:'} logos={publicityLogos} />
      <Newsletter />
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  faqs: PropTypes.arrayOf(PropTypes.object),
  about: PropTypes.string,
  backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  stageVideoSmall: PropTypes.string,
  stageVideoLarge: PropTypes.string,
  publicityLogos: PropTypes.arrayOf(PropTypes.object),
}

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout
      seo_title={post.frontmatter.seo_title}
      seo_description={post.frontmatter.seo_description}
      seo_image={post.frontmatter.image}>
      <IndexPageTemplate
        title={post.frontmatter.title}
        stageText={post.frontmatter.stageText}
        description={post.frontmatter.description}
        faqs={post.frontmatter.faqs}
        backgroundImage={post.frontmatter.image}
        about={post.frontmatter.about}
        stageVideoSmall={post.frontmatter.video_small}
        stageVideoLarge={post.frontmatter.video_large}
        publicityLogos={post.frontmatter.publicity}
        coursesTitle={post.frontmatter.coursesTitle}
        separatorImageVouchers={post.frontmatter.separatorImageVouchers}
        benefits={post.frontmatter.benefits}
        benefitsTitle={post.frontmatter.benefitsTitle}
        USPTitle={post.frontmatter.USPTitle}
        USPBoxHeadline={post.frontmatter.USPBoxHeadline}
        USPBoxBullets={post.frontmatter.USPBoxBullets}
        USPImage={post.frontmatter.USPImage}
        testimonials={post.frontmatter.testimonials}
      />
    </Layout >
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        stageText
        description
        seo_title
        seo_description
        coursesTitle
        image {
          childImageSharp {
            fluid(maxWidth: 1600) {
              src
            }
          }
        }
        video_small
        video_large
        faqs {
          questions
          answer
        }
        benefitsTitle
        benefits {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                src
              }
            }
          }
        }
        USPTitle
        USPBoxHeadline
        USPBoxBullets {
          text
        }
        USPImage {
          publicURL
        }
        publicity {
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                src
              }
            }
          }
        }
        testimonials {
          title
          text
          author
          image {
            childImageSharp {
              fluid(maxWidth: 1600) {
                src
              }
            }
          }
        }
        separatorImageVouchers {
          childImageSharp {
            fluid(maxWidth: 1600) {
              src
            }
          }
        }
      }
    }
  }
`
